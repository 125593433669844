import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import getImage from "../functions/getImage"
import ReactHtmlParser from "react-html-parser"
// import MailingList from "../components/mailingList"
import Vacancies from "../components/vacancies"
import openLink from "../functions/openLink"
import Envelope from "../components/symbols/envelope"
// import Instagram from "../components/symbols/instagram"
// import Twitter from "../components/symbols/twitter"
// import Facebook from "../components/symbols/facebook"
// import Website from "../components/symbols/website"
import LinkedIn from "../components/symbols/linkedin"

export const query = graphql`
  query{
    allWpMeetTheTeam(sort: {order: DESC, fields: date}) {
      edges {
        node {
          meettheteam {
            email
            instagram
            twitter
            website
            linkedin
            role
          }
          title
          featuredImage {
            node {
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                  width
                }
              }
            }
          }
          content
        }
      }
    }
    seoPage:wpPage(slug: {eq: "meet-the-team"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
     wpPage(slug: {eq: "meet-the-team"}) {
      title
      content
     }
  }
`

const emailStaffMember = (email) => {

    document.location.href = `mailto:${email}?subject=Email from Conservation Capital`
}


const IndexPage = ({
  data: {
    allWpMeetTheTeam, seoPage, wpPage
  },
}) => {

  const [modalDisplay, setModalDisplay] = useState(false)
  const [member, setMember] = useState()

  const [loadImageSection, setLoadImageSection] = useState(false)

  useEffect(() => {

      if (typeof window !== "undefined") {
        
        setLoadImageSection(true)
      }
  }, [])

  const showPopUp = item => {
      
      setModalDisplay(true)
      setMember(item)
  }

  const GetMembers = (items) => {

    const images = items?.items?.edges &&
    items?.items.edges.map((item, i) => (
      
      item.node.featuredImage &&
      <div role="button" tabIndex={0} onKeyDown={ () => showPopUp(item.node) } onClick={ () => showPopUp(item.node) } className="item" key={i} style={{ backgroundImage: `url("${getImage(item.node.featuredImage.node.mediaDetails.sizes)}")` }}>

        <h2>{item.node.title}</h2>
        <h3>{item.node.meettheteam.role}</h3>

      </div>
      
    ))

    return images

  }

  return(
  <Layout>
     {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title="Meet The Team" />
    }
    {loadImageSection &&
    <div className="simpleText meet-the-team" style={{backgroundColor:"#2F2F2F"}}>
      
      <h1>{wpPage.title}</h1>

      <div className="intro">

        {ReactHtmlParser(wpPage.content)}

      </div>

      <div className="grid-holder">
        <div className="grid">

          <GetMembers items={allWpMeetTheTeam} />

        </div>
      </div>

      {modalDisplay &&
        <div id="myModal" role="button" tabIndex={0} className="modal black" onKeyDown={() => setModalDisplay(false)} onClick={ () => setModalDisplay(false) }>

          <div className="modal-content black">
          
            <div className="closeHolder">
            <div role="button" tabIndex={0} onKeyDown={() => setModalDisplay(false)} onClick={ () => setModalDisplay(false) } className="close">&times;</div>
            </div>
              
            <div className="links">

              {ReactHtmlParser(member.content)}

              {/*<div role="button" tabIndex={0} className="email" onKeyDown={ () => emailStaffMember(member.meettheteam.email) } onClick={ () => emailStaffMember(member.meettheteam.email)}><Envelope />{member.meettheteam.email}</div>*/}

                <div className="icon-links">
                  {member.meettheteam.email &&
                  <div role="button" tabIndex={0} className="email" onKeyDown={ () => emailStaffMember(member.meettheteam.email) } onClick={ () => emailStaffMember(member.meettheteam.email)}><Envelope />{member.meettheteam.email}</div>
                  }

                  {member.meettheteam.linkedin &&
                  <div role="button" tabIndex={0} className="email" onKeyDown={ () => openLink(member.meettheteam.linkedin) } onClick={ () => openLink(member.meettheteam.linkedin)}><LinkedIn /></div>
                  }

                  {/*<div role="button" tabIndex={0} className="instagram" onKeyDown={() => openLink(member.meettheteam.instagram)} onClick={ () => openLink(member.meettheteam.instagram)}><Instagram /></div>*/}

                  {/*<div role="button" tabIndex={0} className="twitter" onKeyDown={() => openLink(member.meettheteam.twitter)} onClick={ () => openLink(member.meettheteam.twitter)}><Twitter /></div>*/}

                  {/*<div role="button" tabIndex={0} className="facebook" onKeyDown={() => openLink(member.meettheteam.facebook)} onClick={ () => openLink(member.meettheteam.facebook)}><Facebook /></div>*/}

                  {/*<div role="button" tabIndex={0} className="website" onKeyDown={() => openLink(member.meettheteam.website)} onClick={ () => openLink(member.meettheteam.website)}><Website /></div>*/}

                </div>

              </div>  
          
            </div>

          </div>
      }
      
      <Vacancies />

      {/*<div className="mailingList">
        <MailingList styles="mailingList-centre" />
      </div>*/}

    </div>
    }
   
  </Layout>
)
}

export default IndexPage
