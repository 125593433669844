import * as React from "react"
import { Link } from "gatsby"

const IndexPage = () => (

     <div className="vacancies">

        <h2>Thinking of joining our team?</h2>

        <Link activeClassName="active" to="/careers/"><button>CURRENT VACANCIES</button></Link>

      </div>
)

export default IndexPage
