// const openLink = (url) => {
//   if (typeof window !== 'undefined') {
//     window.open(url, "blank")
//   }
// }

// export default openLink


export default function openLink (url) {
  
  if (typeof window !== 'undefined') {
    window.open(url, "blank")
  }
}